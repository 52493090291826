@import "nodeca.core/client/common/_variables"

.emoji-dlg__list
  font-size $font-size-lg
  list-style none
  padding 0
  margin 0

.emoji-dlg__item
  float left

.emoji-dlg__item-link
  text-align center
  display inline-block
  min-width 2em
  height 2em
  line-height 2em
  border-radius 4px
  color $body-color
  transition .2s ease

  &:hover
    background-color $body-bg-secondary
    color $body-color
    text-decoration none
