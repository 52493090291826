@import "nodeca.core/client/common/_variables"

.emoji-autocomplete
  position absolute
  z-index 100
  background $dropdown-bg
  display none
  border 1px solid $dropdown-border-color
  box-shadow $dropdown-box-shadow
  border-radius 4px
  overflow hidden

  &.emoji-autocomplete__m-visible
    display block

.emoji-autocomplete__list
  list-style none
  padding 0
  margin 0

.emoji-autocomplete-item
  padding .25rem .5rem
  color $dropdown-link-color
  transition .2s ease

  &.emoji-autocomplete-item__m-selected
    color $dropdown-link-hover-color
    background-color $dropdown-link-hover-bg
  &:hover
    color $dropdown-link-hover-color
    background-color $dropdown-link-hover-bg

.emoji-autocomplete-item__link
  color inherit

  &:hover
    text-decoration none

.emoji-autocomplete-item__link-text
  padding-left 3px
  color inherit

.emoji-autocomplete-item__search
  font-weight bold
