.navbar-collapse {
  min-width: 0;
}
.js .js-hide {
  display: none !important;
  position: absolute !important;
}
.no-js .nojs-hide {
  display: none !important;
  position: absolute !important;
}
.mdedit {
  min-height: 200px;
  height: 300px;
  position: fixed;
  bottom: -320px;
  left: 0px;
  right: 0px;
  padding: 0.75rem 1rem 0.375rem;
  background-color: #eee;
  box-shadow: 0 0 1rem #eee;
  display: flex;
  flex-direction: column;
  z-index: 1040;
}
.mdedit__resizer {
  cursor: row-resize;
  position: absolute;
  top: -6px;
  left: 50%;
  margin-left: -1.5rem;
  width: 3rem;
  height: 21px;
}
.mdedit__resizer:before,
.mdedit__resizer:after {
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: #bebebe;
  position: absolute;
  top: 9px;
}
.mdedit__resizer:after {
  top: 11px;
}
.mdedit-header__caption {
  line-height: 1em;
  margin-right: 3rem;
  margin-bottom: 0.375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mdedit-footer__controls-right {
  float: right;
  display: flex;
  align-items: center;
}
.mdedit-header__addon {
  margin-bottom: 0.375rem;
}
.mdedit__body {
  display: flex;
  flex-direction: row;
  flex: 2;
  margin-bottom: 0.375rem;
  min-height: inherit;
}
.mdedit__editor {
  display: flex;
  flex-direction: column;
  flex: 2;
  flex-basis: 50%;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.mdedit__editor:focus-within {
  border-color: #a4bfda;
  box-shadow: 0 0 0 0.25rem rgba(74,127,181,0.25);
}
.mdedit__edit-area {
  flex: 2;
  flex-basis: 50%;
  border-radius: 0 0 4px 4px;
  border-width: 0;
  padding: 0.25rem;
  outline: unset;
  overflow: auto;
}
.mdedit__preview {
  flex: 2;
  flex-basis: 50%;
  border: 1px dashed rgba(0,0,0,0.15);
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #fff;
  overflow: auto;
}
.mdedit__preview > .markup {
  overflow: visible;
}
.mdedit__preview .quote__author-avatar {
  background-color: #bdcbd9;
}
.mdedit__preview p:last-child {
  margin-bottom: 0;
}
.mdedit__preview-cut {
  border-style: dashed;
}
.mdedit.mdedit__m-active {
  border: 1px dashed rgba(0,0,0,0.15);
}
.mdedit__toolbar {
  padding: 0.375rem;
  border-bottom: 1px solid rgba(179,179,179,0.15);
  overflow-x: auto;
  white-space: nowrap;
}
.mdedit-toolbar__item {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 1.7em;
  height: 1.5em;
  line-height: 1.5em;
  border: none;
  background: none;
  padding: 0;
  border-radius: 4px;
  color: #525b65;
  transition: 0.2s ease;
}
.mdedit-toolbar__item:hover {
  background-color: #f8f8f8;
  color: #212529;
}
.mdedit-toolbar__sep {
  width: 1px;
  height: 1.2em;
  background: rgba(179,179,179,0.15);
  cursor: auto;
  margin: 0 0.4em;
}
.mdedit__size-controls {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}
.mdedit__fullscreen,
.mdedit__collapser,
.mdedit__narrow {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
}
.mdedit__narrow {
  margin-right: -1rem;
}
.mdedit__collapser .icon-collapse {
  display: none;
}
.mdedit__fullscreen .icon-editor-small {
  display: none;
}
.mdedit__narrow .icon-editor-unnarrow {
  display: none;
}
.mdedit.mdedit__m-fullscreen {
  height: auto !important;
  top: 0;
}
.mdedit.mdedit__m-fullscreen .mdedit__resizer {
  display: none;
}
.mdedit.mdedit__m-fullscreen .mdedit__fullscreen .icon-editor-full {
  display: none;
}
.mdedit.mdedit__m-fullscreen .mdedit__fullscreen .icon-editor-small {
  display: block;
}
.mdedit.mdedit__m-collapsed {
  height: 30px !important;
  padding-top: 0.5rem !important;
  top: unset;
}
.mdedit.mdedit__m-collapsed .mdedit__resizer,
.mdedit.mdedit__m-collapsed .mdedit__body,
.mdedit.mdedit__m-collapsed .mdedit__fullscreen,
.mdedit.mdedit__m-collapsed .mdedit-footer,
.mdedit.mdedit__m-collapsed .mdedit-header__addon {
  display: none;
}
.mdedit.mdedit__m-collapsed .mdedit__collapser .icon-expand {
  display: none;
}
.mdedit.mdedit__m-collapsed .mdedit__collapser .icon-collapse {
  display: block;
}
.mdedit.mdedit__m-resizing {
  transition: none;
}
.mdedit.mdedit__m-narrow {
  margin-left: auto;
  margin-right: auto;
}
.mdedit.mdedit__m-narrow .mdedit__narrow .icon-editor-narrow {
  display: none;
}
.mdedit.mdedit__m-narrow .mdedit__narrow .icon-editor-unnarrow {
  display: block;
}
@media (min-width: 768px) {
  .mdedit.mdedit__m-narrow {
    max-width: calc(100vw - 40px);
  }
}
@media (min-width: 940px) {
  .mdedit.mdedit__m-narrow {
    max-width: 900px;
  }
}
.mdedit-btn__cancel {
  margin-left: 1.4rem !important;
}
.mdedit-btn__preview-xs {
  display: block;
}
.mdedit-btn__preview-sm {
  display: none;
}
@media (min-width: 576px) {
  .mdedit-btn__preview-sm {
    display: block;
  }
  .mdedit-btn__preview-xs {
    display: none;
  }
}
@media (min-width: 768px) {
  .mdedit-btn__preview-sm {
    display: none;
  }
  .mdedit-btn__preview-xs {
    display: none;
  }
  .mdedit__m-narrow .mdedit-btn__preview-sm {
    display: block;
  }
}
.mdedit__editor {
  display: flex;
}
.mdedit__preview {
  display: none;
  border: 1px dashed rgba(0,0,0,0.3);
}
.mdedit__m-preview_mode .mdedit__editor {
  display: none;
}
.mdedit__m-preview_mode .mdedit__preview {
  display: block;
}
@media (min-width: 768px) {
  .mdedit .mdedit__editor {
    display: flex;
  }
  .mdedit .mdedit__preview {
    display: block;
    margin-left: 0.375rem;
  }
  .mdedit__m-narrow .mdedit__editor {
    display: flex;
  }
  .mdedit__m-narrow .mdedit__preview {
    display: none;
    margin-left: 0;
  }
  .mdedit__m-narrow.mdedit__m-preview_mode .mdedit__editor {
    display: none;
  }
  .mdedit__m-narrow.mdedit__m-preview_mode .mdedit__preview {
    display: block;
  }
}
.add-link-dialog.add-link-dialog__m-no-text .add-link-dialog__text {
  display: none;
}
.navbar-collapse {
  min-width: 0;
}
.js .js-hide {
  display: none !important;
  position: absolute !important;
}
.no-js .nojs-hide {
  display: none !important;
  position: absolute !important;
}
.emoji-autocomplete {
  position: absolute;
  z-index: 100;
  background: #fff;
  display: none;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.175);
  border-radius: 4px;
  overflow: hidden;
}
.emoji-autocomplete.emoji-autocomplete__m-visible {
  display: block;
}
.emoji-autocomplete__list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.emoji-autocomplete-item {
  padding: 0.25rem 0.5rem;
  color: #212529;
  transition: 0.2s ease;
}
.emoji-autocomplete-item.emoji-autocomplete-item__m-selected {
  color: #1f2327;
  background-color: #f8f9fa;
}
.emoji-autocomplete-item:hover {
  color: #1f2327;
  background-color: #f8f9fa;
}
.emoji-autocomplete-item__link {
  color: inherit;
}
.emoji-autocomplete-item__link:hover {
  text-decoration: none;
}
.emoji-autocomplete-item__link-text {
  padding-left: 3px;
  color: inherit;
}
.emoji-autocomplete-item__search {
  font-weight: bold;
}
.navbar-collapse {
  min-width: 0;
}
.js .js-hide {
  display: none !important;
  position: absolute !important;
}
.no-js .nojs-hide {
  display: none !important;
  position: absolute !important;
}
.emoji-dlg__list {
  font-size: 1.25rem;
  list-style: none;
  padding: 0;
  margin: 0;
}
.emoji-dlg__item {
  float: left;
}
.emoji-dlg__item-link {
  text-align: center;
  display: inline-block;
  min-width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 4px;
  color: #212529;
  transition: 0.2s ease;
}
.emoji-dlg__item-link:hover {
  background-color: #f8f8f8;
  color: #212529;
  text-decoration: none;
}
